<template>
  <div>
    <v-dialog
      v-model="showModal"
      max-width="400"
    >
      <v-card
        class="mx-auto"
      >
        <v-toolbar
          color="#000"
          dark
        >
          <v-toolbar-title>
            Confirm Project Gates Structure ?
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <p>This action will confirm and create project gates group across your organization.</p>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="green darken-1"
              text
              @click="showModal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="createConfirmed()"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card
      color="#fff"
    >
      <br>
      <base-tree-view-without-actions
        :value="treeData"
        draggable
        droppable
        class="my-tree-view-style"
      />
      <v-card-actions>
        <v-btn
          color="red darken-3"
          @click="cancel()"
        >
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          class="mt-2"
          color="#000"
          @click="create()"
        >
          create
        </v-btn>
      </v-card-actions>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </div>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import Constants from 'src/constants';
  import { walkTreeData } from 'he-tree-vue';

  export default {
    name: 'GroupProjectGates',
    components: {
      'centre-spinner': spinner,
    },
    data () {
      return {
        treeData: [],
        loading: true,
        showModal: false,
        deleteMessage: '',
      };
    },
    computed: {
      createdStructure () {
        return this.$store.getters['projectgates/getCreatedProjectGatesStructure'];
      },
      projectGatesList () {
        return this.$store.getters['projectgates/getProjectGatesList'];
      },
      savedStructure () {
        return this.$store.getters['projectgates/getSavedProjectGatesStructure'];
      },
    },
    async mounted () {
      this.loading = true;
      await this.$store.dispatch('projectgates/fetchProjectGatesList');
      await this.$store.dispatch('projectgates/fetchSavedProjectGatesStructure');
      this.createProjectGatesTreeList();
      this.loading = false;
    },
    methods: {
      createProjectGatesTreeList () {
        if (Object.keys(this.savedStructure).length <= 0) {
          const projectGates = this.projectGatesList;
          const structure = this.createdStructure;
          for (const gate of projectGates) {
            structure[0].children.push({
              id: gate.id,
              text: `${gate.name}`,
            });
          }
          this.treeData = structure;
          return;
        }
          this.treeData = this.createdStructure;
            const availabeProjectGates = [];
            walkTreeData(this.treeData, (node) => {
              if (node.id !== undefined) {
                availabeProjectGates.push(node.id);
              }
            });
          const newProjectGates = this.projectGatesList.filter(i => !availabeProjectGates.includes(i.id));
          for (const newGate of newProjectGates) {
            this.treeData[0].children.push({
              id: newGate.id,
              text: `${newGate.name}`,
            });
          }
      },
      create () {
        if (this.treeData.length > 1 || this.treeData[0].text !== 'All Gates') {
          this.$store.dispatch('alert/onAlert', {
            message: 'All nodes should come under "All Gates".',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.showModal = true;
      },
      async createConfirmed () {
        this.loading = true;
        await this.$store.dispatch('projectgates/groupProjectGates', {
          group: this.treeData,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Project gates group created successfully',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.showModal = false;
          this.$router.push({ name: 'Project Gates' });
        }).finally(
          this.loading = false,
          this.showModal = false,
        );
      },
      cancel () {
        this.$router.push({ name: 'Project Gates' });
      },
    },
  };
</script>
<style scoped>
.my-tree-view-style {
  margin-left: 30px;
}
</style>
